import React, { Fragment } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
// import AspectRatio from "react-aspect-ratio"

export default function CharacterCard({
  name = "",
  title = "",
  imageURL = "",
  paragraphs = [],
  callbackFunc = () => { },
  ratio = "800/600",
  listOrParagraph = "paragraph",
}) {
  return (
    <div className="c-card">
      {imageURL && (
        <div className="card-image-icon">
          <img src={imageURL} className="react-aspect-ratio-placeholder" alt={name} />
          <div className="cicon-container">
            <FontAwesomeIcon
              onClick={callbackFunc}
              className="cicon-info"
              icon={faInfoCircle}
            />
          </div>
        </div>
      )}
      <div className="c-card-content">
        <h2>{name}</h2>
        <p>{title}</p>
        {listOrParagraph === "paragraph" &&
          paragraphs.map((text, index) => (
            <p key={index}>
              {text.split("\n").map((txt, key) => (
                <Fragment key={key}>
                  {txt} {key !== text.split("\n").length - 1 && <br />}
                </Fragment>
              ))}
            </p>
          ))
        }

        {listOrParagraph === "list" && (
          <ul className="dashed-list">
            {paragraphs.map((text, index) => (
              <li key={index}>
                {text}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}
