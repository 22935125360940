import React, { useState } from "react"
import { Helmet } from "react-helmet"
import ColoredHeader from "../../components/coloredHeader"
import Layout from "../../components/layout"
import Separator from "../../components/separator"
import CharacterCard from "../../components/characterCard"
import PeopleModal from "../../components/peopleModal"
import FloatingSearch from "../../components/floatingSearch"
import atlas_lg_blackwhite from "../../../static/background_images/atlas_lg_blackwhite.jpg"
import YACOB_Ezra from "../../../static/people/Ezra_Yacob.jpg"
import donaldson_michael from "../../../static/people/donaldson_michael.jpg"
import BHAKRI_Sandeep from "../../../static/people/BHAKRI_Sandeep.jpg"
import BOYD_John from "../../../static/people/BOYD_John.jpg"
import TERVEEN_Lance from "../../../static/people/TERVEEN_Lance.jpg"
import JANSSEN_Ann from "../../../static/people/JANSSEN_Ann.jpg"
import LEITZELL_JEFF from "../../../static/people/Jeff_Leitzell.jpg"
import CHARLES_SHEPPARD from "../../../static/people/CHARLES_SHEPPARD.jpg"
import Keith_Trasko from "../../../static/people/Trasko_Keith.jpg"
import David_Campbell from "../../../static/people/David_Campbell.jpg"
// import BlankHeadshot from "../../../static/people/Headshot.png" 


const LeadershipCard = ({ name, title }) => {
  return (
    <div className="c-card-content leadership-card">
      <h2>{name}</h2>
      <p>{title}</p>
    </div>
  )
}

const vpsSortedInAlpha = [
  <LeadershipCard
    name="Laura B. Distefano"
    title="VP & Chief Accounting Officer"
  />,
  <LeadershipCard
    name="Gordon D. Goodman"
    title="VP, Environmental and Sustainability"
  />,
  <LeadershipCard name="Pearce W. Hammond" title="VP, Investor Relations" />,
  <LeadershipCard name="Kevin S. Hanzel" title="VP, Audit" />,
  <LeadershipCard
    name="Michele L. Hatz"
    title="VP & Chief Human Resources Officer"
  />,
  <LeadershipCard name="Cory D. Helms" title="VP & General Manager, Denver" />,
  <LeadershipCard
    name="Joseph L. Korenek, Jr."
    title="VP & General Manager, International"
  />,

  <LeadershipCard
    name="Reese T. Lantrip"
    title="VP & General Manager, Fort Worth"
  />,
  <LeadershipCard name="Philip W. Leimer" title="VP, Tax" />,
  <LeadershipCard name="Jeffrey F. Lorson" title="VP, Safety" />,
  <LeadershipCard
    name="Kenneth D. Marbach"
    title="VP & General Manager, Corpus Christi"
  />,
  <LeadershipCard
    name="Jill R. Miller"
    title="VP, Engineering and Acquisitions"
  />,
  <LeadershipCard name="Gary Y. Peng" title="VP, Accounting" />,
  <LeadershipCard name="Pamela R. Roth" title="VP, Government Relations" />,
  <LeadershipCard name="David J. Streit" title="VP, Finance and Treasurer" />,
  <LeadershipCard name="Kyle A. Walker" title="VP & General Manager, Midland" />,
  <LeadershipCard
    name="Gregory D. Williamson"
    title="VP & General Manager, San Antonio"
  />,
  <LeadershipCard
    name="Heath A. Work"
    title="VP & General Manager, Oklahoma City"
  />,
]

const othersSortedInAlpha = [
  <LeadershipCard
    name="Jennifer A. Del Tatto"
    title="Controller, Operations Accounting"
  />,
  <LeadershipCard
    name="Jamie L. Hanafy"
    title="Controller, Land Administration"
  />,
  <LeadershipCard name="Tabitha L. McChesney" title="Controller, Reserves" />,
  <LeadershipCard
    name="D. Seth Woodard"
    title="Controller, Division Accounting"
  />,
  <LeadershipCard
    name="Amos J. Oelking, III"
    title="Deputy Corporate Secretary"
  />,
  <LeadershipCard
    name="Kelly H. Turner"
    title="Deputy Corporate Secretary, Governance and Sustainability"
  />,
]

const mergedSortedInAlpha = [...vpsSortedInAlpha, ...othersSortedInAlpha]

export default function Leadership(props) {
  const [modalState, setModalState] = useState({
    open: false,
    paragraphs: [],
    name: "",
    title: "",
  })

  const onClose = () => {
    setModalState({ open: false, paragraphs: [], name: "", title: "" })
  }

  /// create a function which groups the mergedSortedInAlpha by 3
  const namedPeople = () => {
    let groups = []
    let currentGroup = []

    for (let i = 0; i <= mergedSortedInAlpha.length - 1; i++) {
      if (i % 3 === 0) {
        groups.push(currentGroup)
        currentGroup = []
      }
      currentGroup.push(mergedSortedInAlpha[i])
    }
    if (currentGroup) groups.push(currentGroup)
    return groups
  }

  return (
    <Layout>
      <Helmet>
        <title>Leadership – EOG Resources, Inc.</title>
      </Helmet>
      <ColoredHeader
        title="COMPANY"
        header="Executive Leadership"
        overlayColor1="#d50000"
        overlayColor2="#da3433"
        lineColor="#f95c4f"
        bgImageSRC={atlas_lg_blackwhite}
      />
      <div className="max-width-container absolute desktop-only">
        <FloatingSearch clear />
      </div>
      <div className="page leadership-page no-padding">
        <div className="max-width-container">
          <section className="character-cards-container five-long spaced">
            <CharacterCard
              name="Ezra Yacob"
              title="Chairman of the Board and Chief Executive Officer"
              ratio="3/4"
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "Ezra Yacob",
                  title: "Chairman of the Board and Chief Executive Officer",
                  paragraphs: [
                    `Ezra Y. Yacob was appointed Chairman of the Board, effective October 2022.  Mr. Yacob was previously named Chief Executive Officer and appointed as a Director, effective October 2021.`,
                    `Mr. Yacob previously served as President from January 2021 through September 2021; Executive Vice President, Exploration and Production from 2017 to 2021; Vice President and General Manager in Midland from 2014 to 2017; Division Exploration Manager in Midland from 2013 to 2014; and Division Exploration Manager in Fort Worth from 2012 to 2013.  Prior to that, Mr. Yacob served in various geoscience and leadership positions. He joined the company in 2005 as a Geoscience Associate.`,
                    `Before joining EOG, Mr. Yacob worked for the Minerals Division at the United States Geological Survey.`,
                    `Mr. Yacob earned a Bachelor of Science degree in Geology from the University of Puget Sound, a Master of Science degree in Geology from the Colorado School of Mines and a Master of Business Administration degree from The University of Texas at Tyler.`,
                  ],
                })
              }}
              imageURL={YACOB_Ezra}
            />

            <CharacterCard
              name="Jeff Leitzell"
              title="EVP and Chief Operating Officer"
              ratio="3/4"
              imageURL={LEITZELL_JEFF}
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "Jeff Leitzell",
                  title: "EVP and Chief Operating Officer",
                  paragraphs: [
                    `Jeffrey R. Leitzell was named Executive Vice President and Chief Operating Officer, effective December 2023.  Mr. Leitzell previously served as Executive Vice President, Exploration and Production from May 2021 to December 2023; Vice President and General Manager of EOG's Midland, Texas office from 2017 to 2021; and Operations Manager in Midland from 2015 to 2017. Prior to that, Mr. Leitzell held various engineering roles of increasing responsibility in multiple EOG offices since being hired by EOG as a Completions Engineer in 2008.`,
                    `Mr. Leitzell earned a Bachelor of Science degree in Petroleum and Natural Gas Engineering from West Virginia University.`,
                  ],
                })
              }}
            />

            <CharacterCard
              imageURL={JANSSEN_Ann}
              name="Ann Janssen"
              title="EVP & Chief Financial Officer"
              ratio="3/4"
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "Ann D. Janssen",
                  title: "EVP & Chief Financial Officer",
                  paragraphs: [
                    `Ann D. Janssen is Executive Vice President and Chief Financial Officer, a position she has held since January 2024.  Ms. Janssen is EOG's principal financial officer, and previously served as EOG's principal accounting officer from 2010 to 2024.`,
                    `Ms. Janssen also previously served as Senior Vice President and Chief Accounting Officer from 2018 to 2024; Vice President, Accounting from 2007 to 2018; Controller - Financial Reporting and Planning from 2002 to 2007; Treasurer from 1999 to 2002; and Assistant Treasurer - Planning and Analysis from 1998 to 1999. Ms. Janssen joined a predecessor of EOG in 1995.`,
                    `Ms. Janssen earned a Bachelor of Business Administration degree in Accounting from Texas A&M University and is a Certified Public Accountant.`,
                  ],
                })
              }}
            />

            <CharacterCard
              imageURL={donaldson_michael}
              name="Michael Donaldson"
              ratio="3/4"
              title="EVP, General Counsel and Corporate Secretary"
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "Michael P. Donaldson",
                  title: "EVP, General Counsel & Corporate Secretary",
                  paragraphs: [
                    `Michael P. Donaldson is Executive Vice President, General Counsel and Corporate Secretary,
                  a position he has held since April 2016. Mr. Donaldson previously served as Vice President,
                  General Counsel and Corporate Secretary from 2012 to 2016; Deputy General Counsel and Corporate
                  Secretary from 2010 to 2012; and Corporate Secretary from 2008 to 2010. Mr. Donaldson joined EOG in 2007.`,
                    `Before joining EOG, Mr. Donaldson held in-house legal positions with TODCO, Burlington Resources, Union
                  Texas Petroleum and Pennzoil Company, and was associated with the law firm of Akin Gump Strauss Hauer & Feld.`,
                    `Mr. Donaldson earned a Bachelor of Arts degree in Public Administration and Political Science from Miami
                  University in Oxford, Ohio, a J.D. from the South Texas College of Law and an LL.M. degree from the
                  University of Houston Law Center.`,
                  ],
                })
              }}
            />

            <CharacterCard
              imageURL={BHAKRI_Sandeep}
              name="Sandeep Bhakhri"
              ratio="3/4"
              title="SVP & Chief Information and Technology Officer"
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "Sandeep Bhakhri",
                  title: "SVP & Chief Information and Technology Officer",
                  paragraphs: [
                    `Sandeep Bhakhri is Senior Vice President and Chief Information and Technology Officer,
                  a position he has held since April 2017. Mr. Bhakhri previously served as Vice President
                  and Chief Information Officer from 1995 to 2017, and in various other information technology
                  roles. Mr. Bhakhri joined a predecessor of EOG in 1992.`,
                    `Before joining EOG, Mr. Bhakhri managed development teams for McDermott Marine Construction Company.`,
                    `Mr. Bhakhri earned a Bachelor of Science degree in Electrical Engineering from the Indian
                  Institute of Technology, Delhi and a Master of Business Administration degree from Tulane University.`,
                  ],
                })
              }}
            />
          </section>
          <section className="character-cards-container five-long spaced">
            <CharacterCard
              imageURL={BOYD_John}
              name="John Boyd"
              title="SVP, Operations"
              ratio="3/4"
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "John J. Boyd, III",
                  title: "SVP, Operations",
                  paragraphs: [
                    `John J. Boyd, III is Senior Vice President, Operations, a position he has held since February 2017.
                  Mr. Boyd previously served as Vice President and General Manager in San Antonio from 2015 to 2017;
                  Operations Manager in San Antonio from 2011 to 2015; and Operations Manager in Denver from 2007 to 2011.
                  Mr. Boyd joined EOG in 2002.`,
                    `Before EOG, Mr. Boyd worked for Burlington Resources as a drilling engineer and, prior to that,
                  worked in various roles for an offshore drilling contractor.`,
                    `Mr. Boyd earned a Bachelor of Science degree in Petroleum Engineering from the University of Wyoming.`,
                  ],
                })
              }}
            />
            <CharacterCard
              imageURL={David_Campbell}
              name="David D. Campbell"
              title="SVP, Exploration & Production"
              ratio="3/4"
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "Dave Campbell",
                  title: "SVP, Exploration & Production",
                  paragraphs: [
                    `David D. Campbell is Senior Vice President, Exploration & Production, a position he has held since February 2024. Mr. Campbell previously served as Vice President & General Manager in San Antonio from 2022 to 2024. Prior to that, Mr. Campbell has held various completions engineering and operational roles of increasing responsibility in multiple offices since being hired by EOG as a Senior Production Engineer in 2005. `,
                    `Mr. Campbell earned a Bachelor of Science degree in Chemical Engineering from Texas A&M University.`,
                  ],
                })
              }}
            />
            <CharacterCard
              imageURL={CHARLES_SHEPPARD}
              name="Charlie Sheppard"
              title="SVP, Exploration"
              ratio="3/4"
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "Charles E. Sheppard, III",
                  title: "SVP, Exploration",
                  paragraphs: [
                    `Charles E. Sheppard, III is Senior Vice President, Exploration, a position he has held since February 2022. Mr. Sheppard previously served as Vice President, Exploration in Houston from 2015 to 2022, Division Exploration Manager in Denver from 2013 to 2015, and Division Exploration Manager in Oklahoma from 2011 to 2013. Mr. Sheppard joined EOG in November 2011.`,
                    `Before EOG, Mr. Sheppard worked for Mobil and then ExxonMobil for 24 years in various leadership and geoscience positions. Mr. Sheppard was also an Assistant to the Chair of the Coordinating Sub-Committee for the National Petroleum Council Facing the Hard Truths about Energy Study in 2007.`,
                    `Mr. Sheppard earned a Bachelor of Science degree in Biology and a Master of Science degree in Geology from the State University of New York at Stony Brook.`,
                  ],
                })
              }}
            />
            <CharacterCard
              imageURL={TERVEEN_Lance}
              name="D. Lance Terveen"
              title="SVP, Marketing & Midstream"
              ratio="3/4"
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "D. Lance Terveen",
                  title: "SVP, Marketing & Midstream",
                  paragraphs: [
                    `D. Lance Terveen is Senior Vice President, Marketing & Midstream, a position he has held since May 2024.  Mr. Terveen previously served as Senior Vice President, Marketing from 2017 to 2024, and as Vice President, Marketing Operations from 2014 to 2017. Mr. Terveen joined EOG in 2008 and, from 2008 to 2014, held various marketing positions of increasing responsibility.`,
                    `Before joining EOG, Mr. Terveen worked in various marketing roles.`,
                    `Mr. Terveen earned a Bachelor of Business Administration degree (double majoring in Finance and Economics) from Hardin-Simmons University and a Master of Business Administration degree from Oklahoma City University.`,
                  ],
                })
              }}
            />
            <CharacterCard
              imageURL={Keith_Trasko}
              name="Keith Trasko"
              title="SVP, Exploration & Production"
              ratio="3/4"
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "Keith P. Trasko",
                  title: "SVP, Exploration & Production",
                  paragraphs: [
                    `Keith P. Trasko is Senior Vice President, Exploration & Production, a position he has held since September 2023. Mr. Trasko previously served as Vice President & General Manager in Midland from 2021 to 2023 and as Division Exploration Manager in Midland from 2017 to 2021. Prior to that, Mr. Trasko held various geological and exploration roles of increasing responsibility in multiple offices since being hired by EOG as a geologist in 2007.`,
                    `Mr. Trasko earned a Bachelor of Science degree in Geosciences from Pennsylvania State University and a Master of Science degree in Geological Sciences from The University of Texas at Austin.`,
                  ],
                })
              }}
            />
          </section>

          <Separator width="80%" />

          {namedPeople().map((group, index, array) => {
            return (
              <section
                key={index}
                className="character-cards-container three-long"
                style={{ marginBottom: array.length - 1 === index ? 150 : 0 }}
              >
                {group.map(x => x)}

                {Array.from({ length: 3 - group.length }).map(x => {
                  return <div className="c-card" />
                })}
              </section>
            )
          })}
        </div>
      </div>


      <PeopleModal {...modalState} onClose={onClose} />
    </Layout>
  )
}
